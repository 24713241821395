.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  gap: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 425px;
  /* height: 520px; */
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 3px;
}
.container {
  background-image: linear-gradient(to right bottom, rgba(79, 79, 82, 0.5), rgba(79, 79, 82, 0.5)),
    url("assets/images/form.png");
  background-repeat: no-repeat;
  background-clip: border-box;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  color: #344767;
}
.des {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #344767;
}
input {
  background: #ffffff;
  /* Linear/Dark Snow */
  border: 1px solid #c7ccd0;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #7b809a;
  width: 90%;
  height: 40px;
  padding: 12px;
  font-family: "Roboto";
}
.btn {
  display: block;
  height: 40px;
  border-radius: 8px;
  background: #344767;
  border-radius: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  width: 90%;
  cursor: pointer;
  width: 361px;
}
.footer {
  color: #344767;
  font-weight: 400;
  font-size: 14px;
}
.footer span {
  font-weight: bold;
}
.actions {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.check {
  display: flex;
}
.check input {
  width: 18px;
  display: inline;
  height: 18px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #7b809a;
}
.check span {
  color: #344767;
  margin-left: 10px;
  font-weight: 400;
  font-size: 14px;
}
.actions p {
  color: #344767;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
.valid {
  font-weight: 400;
  font-size: 16px;
  color: #ff3333;
  margin-top: -19px;
  margin-bottom: 0px;
  display: block;
  align-self: flex-start;
  float: left;
  margin-left: 2rem;
}
.btn:disabled {
  cursor: not-allowed;
}
