.Larg {
  background: #f4f4ed;
  max-width: 100%;
  padding: 40px 0px;
}
.LForm {
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1.5fr 3fr;
  max-width: 90%;
  margin: 20px auto;
  border-radius: 8px;
  background-color: #fff;
}
.LForm .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 56px 48px;
  gap: 24px;
  max-width: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  /* gradient */

  background: linear-gradient(
    222.43deg,
    #5fccf5 -62.42%,
    #4a9cd5 -32.69%,
    #356bb5 1.99%,
    #2d59a9 18.51%,
    #274085 35.02%,
    #212861 54.84%,
    #1d1647 73.01%,
    #1a0b37 89.52%,
    #1a0832 102.73%
  );

  /* Inside auto layout */
}
.LForm .info h2 {
  font-family: "Source Sans Pro", sans-serif;

  font-weight: 700;
  font-size: 48px;
  letter-spacing: -0.025em;
  font-feature-settings: "liga" off;
  color: #fff;
}
.LForm .info p {
  font-family: "Source Sans Pro", sans-serif;

  font-weight: 400;
  font-size: 16px;

  font-feature-settings: "liga" off;

  color: #ffffff;

  opacity: 0.7;
}

.LForm .info span {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  font-family: "Inter";
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  font-size: 16px;
  font-feature-settings: "liga" off;

  /* White */

  color: #ffffff;
}
span:last-child {
  margin-top: 0px !important;
}
.LForm form {
  margin: 50px 30px;
}
.LForm form .Two {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  margin: 2rem 0px;
}
.LForm form a {
  margin-top: 15px;
  display: inline-block;
  align-items: center;
  padding: 16px 24px;
  text-decoration: none;
  background: #2d2d77;
  border-radius: 8px;
  font-family: "Source Sans Pro", sans-serif;

  font-weight: 600;
  font-size: 16px;
  border: none;
  text-align: center;
  font-feature-settings: "liga" off;

  color: #ffffff;
}
@media (max-width: 420px) {
  .LForm {
    grid-template-columns: 1fr;
  }
  .LForm .info {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .LForm form .Two {
    grid-template-columns: 1fr;
  }
}
