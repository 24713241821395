.Top {
  background: url("../../../assets/images/Pattern.png");
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  height: 52px;
  position: relative;
}
.phone img {
  cursor: pointer;
}

.menuPhone {
  display: none !important;
}
@media (max-width: 780px) {
  .phone img {
    display: none;
  }
}
@media (max-width: 420px) {
  .navbar img {
    width: 50px;
    height: 16px;
  }
  .navbar a {
    display: none;
  }
  .menuPhone {
    display: block !important;
  }
}
