.LStill {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 200px 20px 100px;
  align-items: center;
  gap: 60px;
}
.LStill {
}
.LStill h2 {
  max-width: 496px;

  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 64px;

  letter-spacing: -0.025em;
  font-feature-settings: "liga" off;

  color: #111827;
}
.LStill p {
  max-width: 496px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  font-feature-settings: "liga" off;
  color: #374151;
}
.LStill a {
  padding-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;
}
@media (max-width: 1024px) {
  .LStill img {
    max-width: 400px;
  }
}
@media (max-width: 770px) {
  .LStill {
    margin: 0px 20px 50px;
    padding: 50px 0px 0px 0px;
  }
  .LStill img {
    max-width: 250px;
  }
  .LStill h2 {
    max-width: 350px;
    font-size: 30px;
  }
  .LStill p {
    max-width: 350px;
    font-size: 30px;
  }
}
@media (max-width: 420px) {
  .LStill img {
    max-width: 150px;
  }
  .LStill h2 {
    max-width: 250px;
    font-size: 20px;
  }
  .LStill p {
    max-width: 250px;
    font-size: 20px;
  }
  .LStill a {
    padding-top: 1rem;
  }
  .LStill a p {
    font-size: 12px;
  }
}
