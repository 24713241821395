.total {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 25px 20px;

  box-sizing: border-box;
}
.statist {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 25px 20px;
  gap: 1rem;
}
.map {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: 25px 20px;
  gap: 1rem;
}
.map form {
  background: #fff;
  border-radius: 8px;
  padding: 20px 15px 10px 15px;
}
.map form header {
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
}
.map form header p {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #344767;
}
.map form header button {
  padding: 12px 24px;
  background: #344767;
  border: 1px solid #babfc3;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  text-align: center;
  font-family: "SF Pro Text";
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #f8f9fa;
}
.map form .enter {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}
.map form .enter label,
.map .two label,
.map .desc label {
  font-family: "Inter";
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #2f3036;
}
.map form .enter input {
  width: 100%;
}
.map .two {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 10px;
}
.map .two div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.map .two select {
  max-width: 19rem;
  height: 40px;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #8f9098;
  color: #8f9098;
  font-weight: 400;
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif;
}
.map .desc {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.map .desc textarea {
  height: 40px;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #8f9098;
  color: #8f9098;
  font-family: "Source Sans Pro", sans-serif;
  resize: none;
}
.map .btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}
.map .btns .cancel {
  padding: 12px 24px;

  text-align: center;
  background: #ffffff;

  border: 1px solid #babfc3;

  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  font-family: "SF Pro Text";
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  color: #202223;
}
.map .btns .add {
  padding: 12px 24px;
  text-align: center;
  background: #344767;
  border: 1px solid #babfc3;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  font-family: "SF Pro Text";
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  color: #f8f9fa;
}
@media (max-width: 1093px) {
  .total {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    row-gap: 2rem;
  }
  .statist {
    grid-template-columns: 1.5fr;
    max-width: 600px;

    margin: auto;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}
@media (max-width: 550px) {
  .statist {
    grid-template-columns: repeat(1, 1fr);
  }
  .map {
    grid-template-columns: 1fr;
  }
}
