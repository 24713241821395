footer {
  padding: 50px 0;
  background: #2d2d77;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
footer p {
  font-family: "Source Sans Pro", sans-serif;

  font-weight: 500;
  font-size: 18px;

  text-align: center;
  font-feature-settings: "liga" off;

  color: #d1d5db;
}
footer .images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 10px;
}
footer .images img {
  cursor: pointer;
}
footer span {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #d1d5db;
}
