.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  padding: 20px 30px 10px 30px;
  background-color: #fff;
}
.header h2 {
  text-transform: capitalize;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  color: #344767;
  font-size: 36px;
}
.header div {
  align-items: flex-end;
  padding-left: 30px;
}
.actions {
  display: flex;
  align-items: center !important;
  justify-content: center;
  gap: 5px;
}
.actions span {
  width: 32px;
  height: 32px;
  background-color: #ffc96b;
  color: #3d2800;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  margin-right: 3px;
}
.actions span p {
  color: #3d2800;
  font-weight: 400;
  font-size: 14px;
  margin-top: -2px;
}
.brand {
  color: #202223;
  font-weight: 500;
  font-size: 14px;
}
@media (max-width: 600px) {
  .header {
  }
  .header h2 {
    margin-right: 10px;
    font-size: 30px;
  }
  .actions span {
    width: 24px;
    height: 24px;
    margin-left: 7px;
    margin-right: 2px;
  }
  .brand {
    font-size: 10px;
  }
}
.icon {
  width: 20px;
  height: 22px;
}
@media (max-width: 499px) {
  .header {
  }
  .header h2 {
    margin-right: 10px;
    font-size: 20px;
  }
  .actions span {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    margin-right: 2px;
  }
  .actions span p {
    font-size: 9px;
  }
  .brand {
    font-size: 8px;
  }
  .actions {
    gap: 1px;
  }
  .icon {
    width: 10px;
    height: 11px;
  }
}
