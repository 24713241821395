.LBecome {
  background: #2d2d77;
  border-radius: 16px;
  max-width: 90%;
  height: 244px;
  margin: 50px auto;
  display: grid;
  grid-template-columns: 3fr 1fr;
  position: relative;
}
.LBecome h2 {
  max-width: 564px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 800;
  font-size: 64px;
  letter-spacing: -0.025em;
  font-feature-settings: "liga" off;
  margin-top: 20px;
  margin-left: 20px;

  color: #ffffff;
}
.LBecome p {
  max-width: 496px;

  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  /* or 117% */
  margin-left: 20px;
  font-feature-settings: "liga" off;

  color: #f3f4f6;
}
.LBecome img {
  z-index: 2;
  position: relative;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 1024px) {
  .LBecome img {
    max-width: 400px;
  }
}
@media (max-width: 770px) {
  .LBecome img {
    display: none;
  }
  .LBecome h2 {
    font-size: 50px;
  }
}
