.LAbout {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  background: #f4f4ed;
  max-width: 100%;
  backface-visibility: hidden;
}
.First {
  display: flex;
  flex-direction: column;
  animation: moveInLeft 3s ease-out;
}
.First .SImg {
  display: flex;
  justify-content: flex-end;
}
.Second {
  animation: moveInRight 3s ease-out;
}
.First h2,
.Second h2 {
  font-family: "Source Sans Pro", sans-serif;
  margin-left: 20px;
  font-weight: 700;
  font-size: 124px;
  line-height: 122px;
  /* or 98% */

  display: flex;
  align-items: center;
  letter-spacing: -7.44px;
  text-transform: uppercase;

  /* gradient */

  background: linear-gradient(
    222.43deg,
    #5fccf5 -62.42%,
    #4a9cd5 -32.69%,
    #356bb5 1.99%,
    #2d59a9 18.51%,
    #274085 35.02%,
    #212861 54.84%,
    #1d1647 73.01%,
    #1a0b37 89.52%,
    #1a0832 102.73%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.First .ImgAText {
  display: flex;
  justify-content: space-around;
}
.mobileImg img {
  max-width: 320px;
  margin: 10px auto;
  transform: rotate(4.03deg);
  /* transform: rotate(-4deg); */
}
.comming {
  max-width: 100%;
  background: #ffee77;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.comming h3 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 48px;
  text-transform: uppercase;
  color: #000000;
}
.MobileBrand {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  padding: 20px 0px;
  margin: 10px auto;
  /* or 98% */

  text-align: center;
  text-transform: uppercase;

  /* gradient */

  background: linear-gradient(
    222.43deg,
    #5fccf5 -62.42%,
    #4a9cd5 -32.69%,
    #356bb5 1.99%,
    #2d59a9 18.51%,
    #274085 35.02%,
    #212861 54.84%,
    #1d1647 73.01%,
    #1a0b37 89.52%,
    #1a0832 102.73%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: none;
}
@media (max-width: 1250px) {
}
@media (max-width: 830px) {
  .First h2,
  .Second h2 {
    font-size: 60px;
  }
}
@media (max-width: 770px) {
  .First h2,
  .Second h2 {
    font-size: 60px;
  }
}
@media (max-width: 670px) {
  .First,
  .mobileImg,
  .Second {
    display: none;
  }
  .comming h3 {
    font-size: 12px;
  }
}
@media (max-width: 420px) {
  .LAbout {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .MobileBrand {
    display: block;
  }
}
@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  80% {
    transform: translate(10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  80% {
    transform: translate(-10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
