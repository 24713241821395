.BoxStat {
  padding: 10px;
  background: #ffffff;
  border-radius: 12px;
  width: 200.4px;
  height: 134px;
}
.BoxStat .BoxParent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.BoxStat .BoxParent p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;

  text-align: right;

  color: #7b809a;
}
.BoxStat .Icon {
  background: linear-gradient(180deg, #3e3d45 0%, #202020 100%);
  border-radius: 12px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
}
.BoxStatTotal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.BoxStatTotal p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: right;

  color: #344767;
}
.Active {
  padding: 10px 3px;
  border-top: 1px solid #f0f2f5;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 16px;
  color: #7b809a;
}
.Active span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #4caf50;
}
