.navbar {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;
  align-items: flex-start; */
  padding: 0px 0px;
  gap: 12px;
  position: fixed;
  background: #344767;
  /* width: 17.4%; */
  height: 100vh;
  /* overflow-y: hidden; */
  height: 100vh !important;
  background: #344767;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15);
  position: relative;
  position: fixed;
  width: 16.6%;
  overflow-x: hidden;
}
.navbar .a {
  text-decoration: none;
  cursor: pointer;
  font-family: "Roboto";
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: inherit;
}
.navbar li {
  list-style: none;
  display: block;
  color: #fff;
  width: 100%;
  height: 40px;
  margin: 20px 0;
  font-size: 15px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.2s ease-in;
}
.par {
  position: relative;
}
li:hover .icon,
.active .icon {
  stroke: #344767;
}
li:hover,
li.active {
  background: #f8f9fa;
  border-radius: 4px 0px 0px 4px;
  /* position: absolute; */
  right: 0;
  color: #344767;
  /* width: 90%; */
  transform: translateX(2%);
}
.icon {
  width: 17px;
  height: 17px;
  stroke: #f8f9fa;
}

.navbar li:first-child {
  margin: 10px 0px;
}
.navbar img {
  margin: 12px 5px 0px 5px;
  width: 50%;
}
@media (max-width: 666px) {
  .navbar {
    display: none;
  }
}
@media (max-width: 400px) {
  .navbar {
    display: none;
  }
}
.sublink {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #f8f9fa;
  padding: 0px;
  margin: 0;
  margin-left: 40px;
  margin-top: -20px;
}
.sublink li {
  padding: 4px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  height: 25px;
  /* margin: 0; */
}
@media (max-width: 770px) {
  .sublink li {
    font-size: 9px;
  }
}
