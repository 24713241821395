.LOur {
  margin: 200px 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 20px;
}
.LOur div {
  max-width: 100%;
}
.LOur h2 {
  max-width: 496px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 800;
  font-size: 64px;
  letter-spacing: -0.025em;
  font-feature-settings: "liga" off;
  color: #111827;
}
.LOur p {
  max-width: 496px;

  font-family: "Source Sans Pro", sans-serif;

  font-weight: 500;
  font-size: 24px;

  font-feature-settings: "liga" off;

  color: #374151;
}
@media (max-width: 1024px) {
  .LOur img {
    max-width: 400px;
  }
}
@media (max-width: 780px) {
  .LOur img {
    max-width: 250px;
  }
  .LOur h2 {
    max-width: 320px;
    font-size: 32px;
  }
  .LOur p {
    max-width: 320px;
    font-size: 32px;
  }
}
@media (max-width: 420px) {
  .LOur {
    margin: 0px 40px;
    padding: 60px 0px 60px 0px;
  }
  .LOur img {
    max-width: 150px;
  }
  .LOur h2 {
    max-width: 220px;
    font-size: 20px;
  }
  .LOur p {
    max-width: 220px;
    font-size: 20px;
  }
}
