.MobileNav {
  /* display: none; */
  display: none !important;
  position: absolute !important;
  top: 0px;
  left: 0px;
}
@media (max-width: 666px) {
  .MobileNav {
    /* display: none; */
    display: block !important;
  }
  .NavMobile li {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    list-style: none;
    padding: 5px 5px;

    color: #344767;
    transition: all 0.2s ease-out;
  }
  .NavMobile li:hover {
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
    background: #344767;
    transform: translateX(5px);
  }
}
