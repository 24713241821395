* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  background-color: #e5e5e5;
}
html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.dash {
  display: flex;
  flex: 1;
  position: relative;
}
.order {
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 0px;
  flex: 1;
  position: relative;
}
.icon {
  width: 17px;
  height: 17px;
  stroke: #f8f9fa;
}
.ck-editor__editable_inline {
  min-height: 300px;
}
@media (max-width: 666px) {
  .order {
    grid-template-columns: 0fr 5fr;
  }
}
@media (max-width: 420px) {
  table {
    font-size: 10px;
  }
}
canvas {
  height: 95% !important;
  width: 100% !important;
}
.css-19s1h9t-MuiTypography-root {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;

  color: #344767;
}
